// src/pages/AboutPage.js
import React from 'react';

const AboutPage = () => {
    console.log("AboutPage рендерится");

    return (
        <div>


            {/* Основной контент с категориями */}
            <div className="category-list">
                <h2>Обзор и цели проекта</h2>
                <p>Проект Экоманер нацелен на создание новой экологической культуры, используя криптовалюту и современные технологии для мотивации людей к сбору и переработке мусора.</p>
                <p><strong>Цель:</strong> Полное очищение планеты от мусора через создание эффективной мотивационной системы.</p>
                <p><strong>Миссия:</strong> Создание цифровой платформы и игрового процесса для организации сбора, сортировки и переработки отходов с привлечением активных участников и доноров.</p>

                <h2>Роли и категории участников</h2>
                <p>В проекте Экоманер выделены следующие основные роли:</p>
                <ul>
                    <li><strong>Участники:</strong> Люди, выполняющие задания по сбору и сортировке мусора.</li>
                    <li><strong>Администраторы:</strong> Волонтеры и сотрудники, которые проверяют выполненные действия и выпускают токены.</li>
                    <li><strong>Доноры и спонсоры:</strong> Поддерживают проект через покупку токенов и пожертвования, помогая поддерживать систему и поощрять участников.</li>
                </ul>

                <h2>Основные процессы проекта</h2>
                <p>Основные процессы, реализуемые в проекте Экоманер:</p>
                <ul>
                    <li>Сортировка мусора: Участники сортируют мусор и отправляют данные о процессе через бота.</li>
                    <li>Мониторинг свалок: Участники фиксируют свалки, отправляют фотографии с геометками, данные проверяются, и за это начисляются токены.</li>
                    <li>Подтверждение пунктов приёма: Участники документируют новые точки приёма мусора, такие как контейнеры и пункты переработки.</li>
                </ul>

                <h2>Цифровая система вознаграждений</h2>
                <p>Цифровая система вознаграждений в проекте Экоманер включает:</p>
                <ul>
                    <li>NFT-токены: Выдаются за подтверждённые действия, такие как сортировка мусора и мониторинг свалок.</li>
                    <li>Экоманер коин: Внутренняя криптовалюта, которая используется для награждения участников и поддержания активности на платформе.</li>
                    <li>Механизм продажи токенов: Доноры могут покупать токены через платформы, такие как OpenSea, поддерживая проект и поощряя активных участников.</li>
                </ul>

                <h2>Игровые и мотивационные элементы</h2>
                <p>В проекте Экоманер интегрированы элементы игры и мотивации:</p>
                <ul>
                    <li>Карма: Система, отражающая уровень участия и усилий участников.</li>
                    <li>Артефакты и игровые баллы: Дополнительные награды за выполненные задания, участие в лотереях и активность на платформе.</li>
                    <li>Социальная сеть: Механизм, позволяющий участникам отслеживать прогресс друг друга и обмениваться опытом.</li>
                </ul>

                <h2>Карта проекта</h2>
                <p>Карта Ecomap предоставляет визуализацию всех зарегистрированных свалок и пунктов приёма:</p>
                <ul>
                    <li>Различные маркеры и обозначения для свалок, точек приёма и других объектов.</li>
                    <li>Возможность отслеживания ликвидированных свалок и новых точек приёма.</li>
                </ul>

                <h2>Технологическая и финансовая инфраструктура</h2>
                <p>Проект Экоманер поддерживается продуманной технологической и финансовой инфраструктурой:</p>
                <ul>
                    <li>Кошелек Экоманер: Система для пополнения, обмена и вывода средств.</li>
                    <li>Внутренняя биржа: Платформа для обмена Экоманер коинов.</li>
                    <li>Финансовые фонды: Ремайнинг фонд, лотерейный фонд и стабилизационный фонд для поддержания активности участников.</li>
                </ul>

                <h2>Методы финансирования и инвестирования</h2>
                <p>Экоманер предлагает различные способы финансирования и поддержки:</p>
                <ul>
                    <li>Спонсорство и донаты: Благотворительные взносы для поддержки проекта.</li>
                    <li>Инвестирование: Возможность купить токены или Экоманер коины с целью долгосрочного дохода.</li>
                    <li>Платежные шлюзы: Поддержка различных способов оплаты для доноров и инвесторов.</li>
                </ul>

                <h2>Партнерские программы и франчайзинг</h2>
                <p>Партнёрская программа и франчайзинг предоставляют возможность сотрудничества:</p>
                <ul>
                    <li>Франчайзинг: Партнёры могут инвестировать и получать доход от активности на определённых территориях.</li>
                    <li>Корпоративные клиенты: Взаимодействие с крупными брендами для организации экологических инициатив, например, спонсорство уборок.</li>
                </ul>

                <h2>Система отчетности и публичности</h2>
                <p>Система отчетности и прозрачности:</p>
                <ul>
                    <li>Прозрачные данные: Публикация отчётов о проделанной работе и активности участников.</li>
                    <li>История транзакций: Публичные данные о купленных и проданных токенах и выполненных заданиях.</li>
                </ul>

                <h2>Образовательные программы и ресурсы</h2>
                <p>Образовательные программы для участников Экоманер:</p>
                <ul>
                    <li>Материалы по сортировке мусора и работе с платформой.</li>
                    <li>Виртуальный экскурсовод: Интерактивное обучение через элементы игры.</li>
                </ul>

                <h2>PR и маркетинговая стратегия</h2>
                <p>PR и маркетинговая стратегия проекта включает:</p>
                <ul>
                    <li>PR-кампании: Привлечение внимания через социальные сети и акции.</li>
                    <li>Реферальная программа: Вовлечение новых участников и поддержка сетевого распространения информации.</li>
                </ul>

                <h2>Собственные разработки и инновации</h2>
                <p>Внедрение инноваций:</p>
                <ul>
                    <li>Цифровая экосистема: Разработка виртуальных мусорных баков и умных контейнеров.</li>
                    <li>Аналитика данных: Сбор и анализ статистики по типам и объему собранного мусора для оптимизации переработки.</li>
                </ul>

                <h2>Социальная сеть Экоманер</h2>
                <p>Социальная сеть Экоманер позволяет участникам общаться и поддерживать друг друга:</p>
                <ul>
                    <li>Коммуникация: Форум, чаты и обмен опытом среди участников.</li>
                    <li>Взаимопомощь: Система быстрого оповещения для ситуаций, требующих коллективных действий.</li>
                </ul>
            </div>
        </div>
    );
};

export default AboutPage;
