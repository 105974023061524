// src/pages/HowItWorksPage.js
import React from 'react';
import '../styles/HowItWorks.css';

const HowItWorksPage = () => {
    return (
        <main className="how-it-works">
            <header>
                <h1>Экоманер</h1>
                <h2>Как это работает</h2>
                <div className="intro">
                    <p>В этом разделе подробно рассказывается, как работает система Экоманер. Следуйте инструкциям и алгоритмам ниже, чтобы понять, как вы можете участвовать и получать вознаграждение за свои действия в проекте.</p>
                </div>
            </header>

            {/* Раздел "Сортировка" */}
            <section id="sorting">
                <h3>Алгоритм "Сортировка"</h3>
                <p>Следуйте этому алгоритму для участия в процессе сортировки мусора и получения вознаграждений:</p>
                <ol>
                    <li className="step">
                        <strong>Шаг 1:</strong> Соберите и отсортируйте мусор.
                    </li>
                    <li className="step">
                        <strong>Шаг 2:</strong> Отправляйтесь в пункт приема мусора и сделайте фото места, где происходит сдача или покупка мусора.
                        
                    </li>
                    <li className="step">
                        <strong>Шаг 3:</strong> Находясь возле пункта приема, сделайте фото отсортированного мусора.
                        
                    </li>
                    <li className="step">
                        <strong>Шаг 4:</strong> Сдайте мусор в пункт приема. Если вам выдали чек, сфотографируйте его вместе с деньгами.
                        
                    </li>
                    <li className="step">
                        <strong>Шаг 5:</strong> Отправьте все фото, геолокацию и информацию в наш Telegram-бот для проверки.
                    </li>
                    <li className="step">
                        <strong>Шаг 6:</strong> После верификации вы получите токены и игровые баллы на ваш счет.
                    </li>
                </ol>
            </section>

            {/* Раздел "Фото свалки" */}
            <section id="dump-photo">
                <h3>Алгоритм "Фото свалки"</h3>
                <p>Инструкция по отправке информации о найденной свалке:</p>
                <ol>
                    <li className="step">
                        <strong>Шаг 1:</strong> Находясь возле свалки, сделайте фото и отправьте его в Telegram-бот вместе с геолокацией.
                        
                    </li>
                    <li className="step">
                        <strong>Шаг 2:</strong> После верификации администратора вы получите токены на свой счет.
                    </li>
                </ol>
            </section>

            {/* Раздел "Участие в голосовании" */}
            <section id="voting">
                <h3>Участие в голосовании</h3>
                <p>Вы можете использовать свои токены для участия в голосовании и влиять на развитие проекта. Вот как это работает:</p>
                <ul>
                    <li>Переходите в раздел голосования в личном кабинете.</li>
                    <li>Выбирайте инициативу или предложение, которое хотите поддержать.</li>
                    <li>Оплачивайте участие токенами и отслеживайте результаты.</li>
                </ul>
            </section>
        </main>
    );
};

export default HowItWorksPage;
