// src/pages/GameplayPage.js
import React from 'react';
import '../styles/Gameplay.css';

const GameplayPage = () => {
    return (
        <main className="gameplay">
            <header>
                <h1>Экоманер</h1>
                <h2>Игровой процесс</h2>

                <div className="intro">
                    <p>Экоманер предлагает игрокам развивать экологически чистый город, используя уникальные NFT-модули и выполняя реальные задания. В этой игре виртуальные и реальные действия объединяются, чтобы создавать устойчивую среду и мотивировать экологическое поведение.</p>
                </div>
            </header>

            <section className="section">
                <h3>Полный список NFT-модулей и их функционал</h3>

                <div className="module" id="bees">
                    <h4>Цивилизация Пчел</h4>
                    <p><strong>Роль:</strong> Сбор и сортировка мусора.</p>
                    <p>Пчелы занимаются сбором мусора и его сортировкой, очищая пространство и направляя отходы на переработку.</p>
                    <ul>
                        <li><strong>Модуль сортировки мусора:</strong> сортирует мусор на органический и неорганический.</li>
                        <li><strong>Модуль обработки органики:</strong> перерабатывает органику в ресурсы.</li>
                        <li><strong>Модуль производства вторсырья:</strong> преобразует мусор в материалы для повторного использования.</li>
                    </ul>
                </div>

                <div className="module" id="ants">
                    <h4>Цивилизация Муравьев</h4>
                    <p><strong>Роль:</strong> Переработка отходов.</p>
                    <p>Муравьи перерабатывают мусор и создают продукцию из вторсырья, способствуя устойчивому развитию города.</p>
                    <ul>
                        <li><strong>Завод по переработке вторсырья:</strong> создает продукцию из отходов.</li>
                        <li><strong>Модуль производства готовой продукции:</strong> создает из вторсырья вещи для длительного использования.</li>
                        <li><strong>Модуль производства "вечных вещей":</strong> позволяет производить продукцию, устойчивающую к износу.</li>
                    </ul>
                </div>

                <div className="module" id="fungi">
                    <h4>Цивилизация Грибов</h4>
                    <p><strong>Роль:</strong> Коммуникация и распространение знаний.</p>
                    <p>Грибы обучают и обеспечивают участников знаниями об экологии, повышая экологическую осведомленность жителей.</p>
                    <ul>
                        <li><strong>Базовый обучающий модуль:</strong> улучшает сортировку мусора за счет информирования жителей.</li>
                        <li><strong>Торговый модуль:</strong> открывает возможности для торговли ресурсами между сотами.</li>
                        <li><strong>Миграционный модуль:</strong> увеличивает население, что повышает продуктивность.</li>
                        <li><strong>Миссионерский модуль:</strong> привлекает новых участников и дает бонусы за расширение сообщества.</li>
                    </ul>
                </div>

                <div className="module" id="bacteria">
                    <h4>Цивилизация Бактерий</h4>
                    <p><strong>Роль:</strong> Производство энергии и гумуса.</p>
                    <p>Бактерии перерабатывают органику в энергию и гумус, поддерживая экологическое равновесие в соте.</p>
                    <ul>
                        <li><strong>Модуль производства электричества:</strong> превращает органику в энергию.</li>
                        <li><strong>Модуль производства тепла:</strong> генерирует тепловую энергию.</li>
                        <li><strong>Модуль биореактора:</strong> создает гумус, улучшая почву.</li>
                    </ul>
                </div>

                <div className="module" id="ecomaner-special">
                    <h4>Экоманер - специальные модули</h4>
                    <p>Эти модули позволяют игрокам участвовать в специальных активностях и социальных мероприятиях.</p>
                    <ul>
                        <li><strong>НФТ-токен "Дерево":</strong> позволяет сажать деревья, улучшая экологический рейтинг города.</li>
                        <li><strong>Онлайн мероприятия:</strong> уникальные события с возможностью получить эксклюзивные NFT.</li>
                    </ul>
                </div>
            </section>

            <section className="section">
                <h3>Игровой процесс и механики</h3>

                <div className="gameplay-feature" id="city-management">
                    <h4>Управление виртуальным городом (сотой)</h4>
                    <p>Каждый игрок развивает свою соту, наделенную уникальным индексом и численностью населения. Сота ежедневно производит мусор, который нужно либо переработать, либо утилизировать, повышая экологический рейтинг.</p>
                </div>

                <div className="gameplay-feature" id="real-tasks">
                    <h4>Интеграция с реальными заданиями</h4>
                    <p>Игроки выполняют задания в реальной жизни, такие как сортировка мусора или посадка деревьев, и получают игровые награды за свои усилия. Эти действия помогают улучшать инфраструктуру города.</p>
                </div>

                <div className="gameplay-feature" id="nft-economy">
                    <h4>Экономика на основе NFT</h4>
                    <p>Каждый модуль и действие в игре закреплены за NFT-токенами, которые игроки могут продавать или обменивать, что позволяет создавать экономику, основанную на экологически значимых действиях.</p>
                </div>

                <div className="gameplay-feature" id="collaboration">
                    <h4>Сотрудничество и обмен ресурсами</h4>
                    <p>Игроки могут объединяться, обмениваться ресурсами и делиться опытом для достижения общих целей и создания более эффективной экосистемы.</p>
                </div>
            </section>

            <section className="section">
                <h3>Цели игры и развитие</h3>
                <p>Цель игрока — развить соту до максимального уровня, достигнуть статуса "Экоманера" и войти в зал славы. Для этого необходимо выполнить все реальные задания, установить максимальные модули, участвовать в обучающих программах и посадить деревья.</p>
                <p>Достигнув статуса "Экоманера", игрок может открыть новые соты и получить доступ к уникальным возможностям.</p>
                <p><em>Также разрабатывается настольная игра "Экоманер", которая перенесет экологические механики и задания в увлекательный формат для совместной игры.</em></p>
            </section>
        </main>
    );
};

export default GameplayPage;
