// src/pages/FaqPage.js
import React from 'react';
import '../styles/Faq.css';

const FaqPage = () => {
    return (
        <main className="faq">
            <header>
                <h1>Экоманер</h1>
                <h2>Часто задаваемые вопросы (FAQ)</h2>
                <p>Экоманер объединяет участие, экологию и технологии. Здесь вы найдете ответы на часто задаваемые вопросы о проекте и его особенностях.</p>
            </header>

            <section className="section">
                <h3>Основные вопросы</h3>

                <div className="faq-item">
                    <h4>1. Что такое Экоманер и каковы его цели?</h4>
                    <p>Экоманер — проект, направленный на сокращение отходов и защиту экологии. Участники выполняют экологические задания и развивают виртуальные города, способствуя созданию устойчивого будущего.</p>
                </div>

                <div className="faq-item">
                    <h4>2. Как начать участвовать в проекте?</h4>
                    <p>Для участия необходимо зарегистрироваться, создать криптовалютный кошелек и приобрести NFT-токен, привязанный к виртуальной соте. Это позволяет выполнять задания и развивать город.</p>
                </div>

                <div className="faq-item">
                    <h4>3. Что такое соты и как они работают?</h4>
                    <p>Соты — это виртуальные участки, где игроки строят и развивают инфраструктуру для переработки отходов и улучшения экологии. Игроки могут объединяться в десятки вокруг главных сот.</p>
                </div>

                <div className="faq-item">
                    <h4>4. Какую роль играют цивилизации в проекте?</h4>
                    <p>Четыре цивилизации — Пчелы, Муравьи, Грибы и Бактерии — отвечают за разные аспекты переработки и экологии. Каждая цивилизация помогает строить устойчивую инфраструктуру города.</p>
                </div>

                <div className="faq-item">
                    <h4>5. Что такое NFT-токены и как их получить?</h4>
                    <p>NFT-токены — это цифровые активы, которые игроки получают за выполнение заданий и достижение целей. Их можно приобрести через платформу или заработать в проекте.</p>
                </div>

                <div className="faq-item">
                    <h4>6. Чем Экоманер коин отличается от NFT-токенов?</h4>
                    <p>Экоманер коин — это внутренняя валюта проекта, используемая для обмена и расчетов. NFT-токены подтверждают уникальные достижения участников и могут быть проданы или обменяны.</p>
                </div>

                <div className="faq-item">
                    <h4>7. Как работает реферальная система?</h4>
                    <p>Каждый участник может приглашать новых людей, раздавая реферальные ссылки и помогая проекту расти. Приглашенные игроки добавляют баллы участнику и его сети.</p>
                </div>

                <div className="faq-item">
                    <h4>8. Какую образовательную программу предлагает проект?</h4>
                    <p>Проект обучает экологии, крипто-грамотности и коммуникативным навыкам, что позволяет участникам эффективно взаимодействовать и работать с технологиями.</p>
                </div>

                <div className="faq-item">
                    <h4>9. Что такое биржа возможностей?</h4>
                    <p>Биржа возможностей — это площадка, где участники обмениваются навыками и ресурсами. Можно подать заявку "Могу/Умею" или "Надо/Нуждаюсь" для обмена услугами.</p>
                </div>

                <div className="faq-item">
                    <h4>10. Как работает касса взаимопомощи?</h4>
                    <p>Касса взаимопомощи позволяет участникам получать поддержку в трудные времена, внося вклад в общую систему помощи проекта.</p>
                </div>

                <div className="faq-item">
                    <h4>11. Могу ли я обменивать ресурсы с другими участниками?</h4>
                    <p>Да, участники могут делиться ресурсами и знаниями, помогая друг другу. Объединение ресурсов укрепляет сообщество и его устойчивость.</p>
                </div>

                <div className="faq-item">
                    <h4>12. Как действует экологическая лотерея?</h4>
                    <p>Экологическая лотерея поддерживает природоохранные фонды. За каждый купленный билет участники получают экоманер токены.</p>
                </div>

                <div className="faq-item">
                    <h4>13. Как проект поощряет использование восстановленных вещей?</h4>
                    <p>Экоманер поддерживает использование восстановленных вещей, помогая снизить объем отходов и пропагандируя экологическую осознанность.</p>
                </div>

                <div className="faq-item">
                    <h4>14. Как происходит верификация участников?</h4>
                    <p>Верификация включает проверку данных через бота, который фиксирует и защищает информацию, связанную с участником, подтверждая подлинность действий.</p>
                </div>

                <div className="faq-item">
                    <h4>15. Какие задачи выполняют грибные и бактериальные модули?</h4>
                    <p>Грибы и бактерии перерабатывают органические отходы, производя гумус и биоэнергию, что помогает улучшать почвы и экосистемы.</p>
                </div>

                <div className="faq-item">
                    <h4>16. Где размещаются NFT-токены проекта?</h4>
                    <p>NFT-токены Экоманера размещаются на платформе OpenSea, где они доступны для покупки и обмена.</p>
                </div>

                <div className="faq-item">
                    <h4>17. Как получить доступ к сотам в проекте?</h4>
                    <p>Участники могут занять одну из 7500 виртуальных сот, создавая инфраструктуру для переработки отходов и улучшения экологии.</p>
                </div>

                <div className="faq-item">
                    <h4>18. Можно ли вывести средства из проекта?</h4>
                    <p>Токены и ресурсы можно обменивать или выводить, следуя правилам и процедурам платформы.</p>
                </div>

                <div className="faq-item">
                    <h4>19. Какие бонусы дает спонсорство новых сот?</h4>
                    <p>Каждая новая сота, поддержанная участником, добавляет ему бонусные баллы и усиливает структуру его сети.</p>
                </div>

                <div className="faq-item">
                    <h4>20. Как можно внести вклад в проект, не участвуя в игре?</h4>
                    <p>Вы можете поддерживать проект, участвуя в реальных экологических инициативах, делать пожертвования и распространять информацию об экологии и проекте.</p>
                </div>
            </section>
        </main>
    );
};

export default FaqPage;
