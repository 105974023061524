// src/pages/ParticipationPage.js
import React from 'react';
import '../styles/Participation.css';

const ParticipationPage = () => {
    return (
        <main className="participation">
            <header>
                <h1>Экоманер</h1>
                <h2>Участие в проекте</h2>
                <p>Экоманер предоставляет широкий спектр возможностей для участников, включая реферальные программы, обучение, обмен ресурсами и доступ к поддерживающим структурам, таким как касса взаимопомощи и экологическая лотерея. Каждый может внести вклад и стать частью устойчивой экологической сети.</p>
            </header>

            <section className="section">
                <h3>Возможности для участников</h3>

                <div className="feature" id="referral-system">
                    <h4>Реферальная система</h4>
                    <p>Участники получают уникальные реферальные ссылки, позволяющие приглашать новых людей. Каждый участник может занять одну из 7500 сот в Украине и строить собственную структуру, распространять проект и привлекать новых участников, развивая и укрепляя сообщество.</p>
                </div>

                <div className="feature" id="education">
                    <h4>Образовательные программы</h4>
                    <p>Проект предлагает участникам обучение по трем основным направлениям:</p>
                    <ul>
                        <li><strong>Экологическая грамотность:</strong> основы правильной сортировки, переработки и минимизации отходов.</li>
                        <li><strong>Коммуникативные навыки:</strong> обучение эффективному взаимодействию, построению команды и привлечению сторонников.</li>
                        <li><strong>Крипто-азбука:</strong> основы использования криптовалют и NFT, необходимые для полноценного участия в проекте.</li>
                    </ul>
                </div>

                <div className="feature" id="network-structure">
                    <h4>Сетевая структура и принцип десятков</h4>
                    <p>Экоманер использует сетевую организацию по десятичной системе: каждая сота объединяется в десяток вокруг главной соты и может быть спонсором для девяти новых сот. Этот подход формирует устойчивую структуру, где каждый участник оказывает поддержку и получает поддержку в ответ.</p>
                </div>

                <div className="feature" id="resource-sharing">
                    <h4>Объединение ресурсов и биржа возможностей</h4>
                    <p>Экоманер создает биржу возможностей, где участники могут обмениваться навыками, услугами и ресурсами по категориям "Могу/Умею" и "Надо/Нуждаюсь". Это уникальная платформа для взаимной поддержки, обмена связями и коллективного использования доступных ресурсов.</p>
                </div>

                <div className="feature" id="mutual-assistance-fund">
                    <h4>Касса взаимопомощи</h4>
                    <p>Проект включает кассу взаимопомощи, которая действует как децентрализованный инвестиционный фонд. Участники могут получать финансовую поддержку в трудных ситуациях и вносить вклад в общий фонд для помощи другим.</p>
                </div>

                <div className="feature" id="eco-lottery">
                    <h4>Экологическая лотерея</h4>
                    <p>Участники могут участвовать в экологической лотерее, где каждый купленный билет поддерживает природоохранные фонды. За покупку билетов участники получают токены Экоманера, что добавляет элемент игры и мотивации для поддержки экологии.</p>
                </div>
            </section>
        </main>
    );
};

export default ParticipationPage;
